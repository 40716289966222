.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.home-hero-image {
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(252, 139, 139, 0.15);
  transition: all 0.3s ease-in-out;
}

.home-hero-image:hover {
  transform: scale(1.02);
  box-shadow: 0 0 30px rgba(252, 139, 139, 0.25);
}

.myAvtar img {
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #863636;
}

.about-img img {
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(252, 139, 139, 0.15);
  transition: all 0.3s ease-in-out;
}

.tech-icons {
  position: relative;
  transition: all 0.3s ease-in-out;
}

.tech-label {
  color: #ffffff;
  font-size: 0.9rem;
  margin-top: 8px;
  opacity: 0.9;
  text-align: center;
}

.tech-icons:hover {
  transform: translateY(-5px);
}

.tech-icons a {
  text-decoration: none;
  color: inherit;
}

.project-card-view {
  background: rgba(193, 146, 146, 0.05) !important;
  border: 1px solid rgba(193, 146, 146, 0.15) !important;
  border-radius: 15px !important;
  box-shadow: 0 4px 20px rgba(193, 146, 146, 0.1) !important;
  transition: all 0.3s ease-in-out !important;
}

.project-card-view:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 25px rgba(193, 146, 146, 0.2) !important;
}

.project-card-view img {
  border-radius: 15px 15px 0 0;
  height: 200px;
  object-fit: cover;
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
