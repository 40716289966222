@keyframes snowfall {
  0% {
    transform: translateY(-10vh) translateX(-20px) rotate(0deg);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) translateX(20px) rotate(360deg);
    opacity: 0.4;
  }
}

@keyframes snowfall-alt {
  0% {
    transform: translateY(-10vh) translateX(20px) rotate(0deg);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) translateX(-20px) rotate(-360deg);
    opacity: 0.4;
  }
}

.snowfall {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}

.snowflake {
  position: absolute;
  background: white;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.3);
  animation: snowfall linear infinite;
}

/* Create many different variations of snowflakes */
.snowflake:nth-child(5n) {
  width: 3px;
  height: 3px;
  animation-duration: 6s;
  animation-delay: -1s;
  filter: blur(0.5px);
}

.snowflake:nth-child(5n + 1) {
  width: 5px;
  height: 5px;
  animation-duration: 8s;
  animation-delay: -3s;
  filter: blur(0.8px);
}

.snowflake:nth-child(5n + 2) {
  width: 7px;
  height: 7px;
  animation-duration: 10s;
  animation-delay: -5s;
  filter: blur(1px);
}

.snowflake:nth-child(5n + 3) {
  width: 4px;
  height: 4px;
  animation-duration: 7s;
  animation-delay: -2s;
  animation-name: snowfall-alt;
  filter: blur(0.7px);
}

.snowflake:nth-child(5n + 4) {
  width: 6px;
  height: 6px;
  animation-duration: 9s;
  animation-delay: -4s;
  animation-name: snowfall-alt;
  filter: blur(0.9px);
}

/* Distribute snowflakes randomly across the screen */
.snowflake:nth-child(7n) { left: 5%; }
.snowflake:nth-child(7n + 1) { left: 15%; }
.snowflake:nth-child(7n + 2) { left: 30%; }
.snowflake:nth-child(7n + 3) { left: 45%; }
.snowflake:nth-child(7n + 4) { left: 60%; }
.snowflake:nth-child(7n + 5) { left: 75%; }
.snowflake:nth-child(7n + 6) { left: 90%; }

.snow-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  cursor: pointer;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: all 0.3s ease;
  padding: 0;
}

.snow-toggle:hover {
  transform: scale(1.1);
  background: rgba(255, 255, 255, 0.3);
}

.snow-toggle:active {
  transform: scale(0.95);
}

.snow-toggle:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.3);
} 