html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(26, 17, 16, 0.582),
    rgba(24, 8, 8, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(26, 17, 16, 0.678),
    rgba(24, 10, 10, 0.863)
  );

  --imp-text-color: #ff7070;
}

.purple {
  color: var(--imp-text-color) !important;
  text-decoration-color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2d1919;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(216, 121, 121, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(235, 130, 130, 0.911);
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #2e1b1ba9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(29, 5, 5, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #f45050 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #181a27 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 1.4em !important;
  width: 2.5em !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #f55b5b;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
  z-index: 0 !important;
  pointer-events: none !important;
}

.home-header {
  padding-top: 80px !important;
}

.home-section {
  position: relative;
  z-index: -1;
  background-image: var(--image-gradient), url(./Assets/home-bg.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.home-content {
  padding: 9rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.main-name {
  color: #ff5f5f;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #ff6a6a !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #ff6262 !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
}

.home-about-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #7a1818;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #951f1f;
}

.home-social-icons:hover {
  color: #9e2020;
  box-shadow: 0 0 5px #9e2020;
  text-shadow: 0 0 2px #9e2020;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #860c0c !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(22, 4, 4);
  padding-top: 10px !important;
  padding-bottom: 8px !important;
  flex-shrink: 0;
  width: 100%;
  margin-top: auto;
  bottom: 0;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #c08888 !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px;
  background-image: var(--section-background-color) !important;
  z-index: 1 !important;
  min-height: calc(100vh - 60px);
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  background-color: transparent !important;
  box-shadow: 0 4px 5px 3px rgba(136, 53, 53, 0.459) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(144, 72, 72, 0.561) !important;
}

.blog-card {
  padding: 0;
  margin-bottom: 2rem;
  cursor: pointer;
  position: relative !important;
  z-index: 1 !important;
  width: 100%;
}

.blog-link {
  text-decoration: none !important;
  color: inherit !important;
}

.blog-card-inner {
  background: rgba(255, 255, 255, 0.03);
  padding: 2rem;
  border-radius: 16px;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.blog-card-inner:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(240, 112, 112, 0.2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.blog-title {
  color: var(--imp-text-color);
  margin-bottom: 1.2rem;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.blog-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.95em;
  margin-bottom: 1.5em;
}

.blog-meta span {
  display: flex;
  align-items: center;
  gap: 6px;
}

.separator {
  color: rgba(240, 112, 112, 0.3);
}

.blog-excerpt {
  color: rgba(255, 255, 255, 0.85);
  margin-bottom: 1.5rem;
  line-height: 1.65;
  font-size: 0.95rem;
  max-width: 650px;
}

.read-more {
  color: var(--imp-text-color);
  font-weight: 500;
  font-size: 0.95rem;
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  align-items: center;
  gap: 6px;
}

.blog-card-inner:hover .read-more {
  transform: translateX(8px);
  color: #d995f7;
}

/* Blog Post Styles */
.blog-post {
  background: transparent;
  padding: 0;
  border-radius: 0;
  max-width: 800px;
  margin: 0 auto;
  color: #ccd6f6;
}

.blog-post .blog-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--imp-text-color);
  margin-bottom: 1rem;
  line-height: 1.3;
  text-align: left;
}

.blog-post .blog-meta {
  font-size: 1rem;
  color: #8892b0;
  margin-bottom: 3rem;
  text-align: left;
}

.markdown-content {
  color: #ccd6f6;
  line-height: 1.8;
  font-size: 1.1rem;
  text-align: left;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  color: var(--imp-text-color);
  margin-top: 0.25em;
  margin-bottom: 0.75em;
  font-weight: 600;
  line-height: 1.3;
}

.markdown-content h1 {
  font-size: 2rem;
}
.markdown-content h2 {
  font-size: 1.75rem;
}
.markdown-content h3 {
  font-size: 1.5rem;
}
.markdown-content h4 {
  font-size: 1.25rem;
}

.markdown-content p {
  margin-bottom: 1.8em;
  line-height: 1.8;
}

.markdown-content a {
  color: var(--imp-text-color);
  text-decoration: none;
  border-bottom: 1px solid var(--imp-text-color);
  transition: all 0.2s ease;
}

a {
  color: var(--imp-text-color) !important;
  text-decoration-color: var(--imp-text-color) !important;
  transition: all 0.3s ease;
}

a:hover {
  color: var(--imp-text-color);
  opacity: 0.8;
}

.markdown-content a:hover {
  opacity: 0.8;
  border-bottom-width: 2px;
}

.markdown-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 2em 0;
  display: block;
}

.markdown-content blockquote {
  border-left: 4px solid var(--imp-text-color);
  padding: 0.8em 1.2em;
  margin: 2em 0;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0 4px 4px 0;
}

.markdown-content blockquote p {
  margin: 0;
  color: #8892b0;
  font-style: italic;
}

.markdown-content code {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-family: "Fira Code", monospace;
  font-size: 0.9em;
}

.markdown-content pre {
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5em;
  border-radius: 8px;
  overflow-x: auto;
  margin: 2em 0;
}

.markdown-content pre code {
  background: transparent;
  padding: 0;
  font-size: 0.9em;
  line-height: 1.6;
}

.markdown-content ul,
.markdown-content ol {
  margin: 1.5em 0;
  padding-left: 2em;
}

.markdown-content li {
  margin-bottom: 0.8em;
}

.back-link {
  display: inline-flex;
  align-items: center;
  margin-bottom: 3rem;
  padding: 0.6rem 1.2rem;
  background: rgba(240, 112, 112, 0.1);
  border: 1px solid var(--imp-text-color);
  border-radius: 8px;
  color: var(--imp-text-color);
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
}

.back-link:hover {
  background: rgba(240, 112, 112, 0.2);
  transform: translateX(-5px);
  text-decoration: none;
  color: var(--imp-text-color);
}

.back-link svg {
  margin-right: 8px;
  transition: transform 0.3s ease;
}

.back-link:hover svg {
  transform: translateX(-3px);
}

.loading-spinner {
  color: white;
  text-align: center;
  font-size: 1.2em;
  padding: 2em;
}

.error-message {
  color: #ff6b6b;
  text-align: center;
  font-size: 1.2em;
  padding: 2em;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #863636 !important;
  border-color: #863636 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #c52020d7 !important;
  border-color: #c52020d7 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
  margin-bottom: 50px;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(230, 137, 137, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(168, 4, 4, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(230, 115, 115, 0.883) !important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #ce4c4c5e !important;
  border-color: #ce4c4c5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #d34d4d86 !important;
  border-color: #d34d4d86 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.loading-spinner {
  color: white;
  text-align: center;
  font-size: 1.2em;
  padding: 2em;
}

.error-message {
  color: #ff6b6b;
  text-align: center;
  font-size: 1.2em;
  padding: 2em;
}

.markdown-content {
  color: white;
  line-height: 1.8;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  color: var(--imp-text-color);
  margin-top: 0.25em;
  margin-bottom: 0.75em;
  font-weight: 600;
  line-height: 1.3;
}

.markdown-content p {
  margin-bottom: 1.2em;
}

.markdown-content a {
  color: var(--imp-text-color);
  text-decoration: none;
  transition: all 0.3s ease;
}

.markdown-content a:hover {
  text-decoration: underline;
}

.markdown-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 1.5em 0;
}

.markdown-content code {
  background-color: #2d3748;
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-family: monospace;
}

.markdown-content pre {
  background-color: #2d3748;
  padding: 1em;
  border-radius: 8px;
  overflow-x: auto;
  margin: 1.5em 0;
}

/* Update the Container width for blog list */
.project-section .container {
  padding: 0 20px;
}

/* Add specific width for blog list container */
.project-section.blog-list .container {
  max-width: 750px !important;
  margin: 0 auto;
}

/* Keep project cards at their original width */
.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

/* Adjust blog card width to match new container */
.blog-card {
  padding: 0; /* Remove padding since container has padding */
  margin-bottom: 2rem;
  cursor: pointer;
  position: relative !important;
  z-index: 1 !important;
  width: 100%; /* Take full width of new container */
}

/* Adjust inner padding for better proportions */
.blog-card-inner {
  padding: 2rem; /* Slightly reduced padding */
}

/* Adjust title size for new width */
.blog-title {
  font-size: 1.5rem; /* Slightly smaller for better fit */
}

/* Make excerpt text slightly smaller */
.blog-excerpt {
  font-size: 0.95rem;
  line-height: 1.65;
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .project-section .container {
    padding: 0 15px;
  }

  .blog-card-inner {
    padding: 1.5rem;
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(41 20 20), rgb(35 15 15));
}
